import React from 'react';

import SEO from "components/Seo";
import Header from "components/Header";
import Footer from "components/Footer";

import LayoutMain from "components/layout/layoutMain";
import LayoutPanel from "components/layout/layoutPanel";

import Faq from 'components/common/Faq';
import Link from 'components/common/Link';
import Image from 'components/common/Image';
import { LinkModal } from 'kh-common-components';
import Panel from "components/common/Panel";

import BlogCarousel from 'components/cards/BlogCarousel';

import faqData from 'constants/components/accordion-list/carbon-offset-faq';


import { modalContents } from 'constants/modal-content';
import panelData from 'constants/carbon-offset/panel';

// asset images
// https://www.gatsbyjs.com/docs/how-to/images-and-media/importing-assets-into-files/
import heroBanner from 'images/carbon-offset/co-banner.png';
import coInfographic from 'images/carbon-offset/co-infographic.svg';
import coNeutralProduct from 'images/carbon-offset/climateactive-certified-product.png';
import wescefLogo from 'images/carbon-offset/logo-wescef.svg';


function CarbonOffsetPage() {
    const srcFlowmojiInvoice = '/icons/flowmoji/flowmoji-invoice.svg';
	const viewPriceModalFaq = <div>
	<LinkModal 
		modalContents={modalContents}
		useModal="true"
		displayContent={<small>Terms & conditions apply</small>}
		title="Terms & Conditions"
		modalTitle="Terms & Conditions"
		modalContentName="terms-carbonoffset-opt-in"
		okayButtonLabel="Close"
	/>
</div>

    const accordionItem = {
        "title": `How much is my carbon offset contribution likely to be?`,
        "content": `<p>Your carbon offset contribution is dependent on how much natural gas your household consumes in a billing cycle.</p><p>Billing cycles are seasonal, so there will be fluctuation in your carbon offset contribution throughout the year.</</p><p>If you are an existing customer, you can estimate your carbon offset contribution by looking at a previous bill and multiplying the number of units used by the unit price. <a href='/natural-gas/plans-and-pricing/natural-gas-standard-prices-fees' title='View prices and fess for all natural gas customers'>View prices and fees for all natural gas customers</a>.</p><p>If you are a new customer, give our customer service team a call on <a href='tel:132180' title='Call us on 13 21 80'>13 21 80</a> to discuss your estimated carbon offset contribution.</p>`
        
    };

	let modifiedAccordionList = faqData.concat(accordionItem);

	return (
		<>
            <SEO title="Carbon Offsets | Kleenheat"
				 titleBespoke="true"
				 description="Join our Carbon Offsets Program and offset the carbon emissions from your household's natural gas usage."
        	/>
            <Header />
            <LayoutMain>

            	{/* hero banner */}
            	<LayoutPanel background="mer-bg--primary-cyan"                 
			                 padding="mer-p-ju"
                             colClass="mer-panel--layout-reverse"
			                 theme="mer-theme--cyan">
			        <div className="container">
			            <div className="row mer-panel justify-content-center align-items-center">

                            <div className="col-12 col-sm-6 mer-text--size-lg order-sm-2">
                                <div className="content">
                                    <h2>Carbon offsetting</h2>
                                    <p>Kleenheat’s residential natural gas customers can opt in to our Carbon Offset Program.</p>
									<p>If you opt in, your carbon offset contribution will cover the cost of Kleenheat acquiring offset units to help compensate for the emissions associated with your household’s natural gas usage.<sup>*</sup></p>
									<div>                  
										<small><LinkModal
										modalContents={modalContents}
										useModal="true"
										title="Terms & Conditions"
										// linkClass=""
										displayContent={<span><sup>*</sup>Terms and conditions apply</span>}
										modalTitle="Terms & Conditions"
										modalContentName="terms-carbonoffset-opt-in"
										okayButtonLabel="Close"
										/></small>
									</div>

                                    <div className="mer-panel__actions mer-button-group">
                                        <Link text="New customer"
                                                link="/sign-up"
                                                linkClass="mer-button mer-button--primary" />
                                        <Link text="Existing customers"
                                                link="/my-account/carbon-offset"
                                                linkClass="mer-button mer-button--secondary" />
                                    </div>
                                </div>
                            </div>
                            <div className="mer-panel__images col-10 col-sm-6 order-sm-1">
                                <img src={heroBanner} 
                                     className="mer-panel__image"
                                     alt="Carvings of trees and house with heart" />
                            </div>
                            
			            </div>
					</div>
			    </LayoutPanel>

			    {/* 1 */}
		    	<LayoutPanel background="mer-bg--gs-grey-lighter"
			                 padding="mer-py-lg"
			                 id="basics">
			        <div className="container">
			            <div className="row mer-panel">

			              	<div className="col-10 col-sm-7 col-lg-6">
			              		<div className="content">
				                	<h2 className="mer-typography--headline3">What is carbon offsetting?</h2>
                                    <p>Carbon offset units are used to compensate for the residual emissions an organisation produces to help reduce its carbon footprint where it may not be able to reduce its direct emissions.</p>
									<p>Offset units are generated by projects that reduce, remove or capture emissions from the atmosphere, such as reforestation or renewable energy.</p>
									<p>By purchasing carbon offsets, organisations support projects that help reduce emissions contributing to climate change.</p>
	          					</div>
			              	</div>

							<div className="mer-panel__images col-5 col-sm-3 offset-sm-1">
								<img className="mer-panel__image" src={coInfographic} alt="Carbon offset infographic" />
						    </div>
			            </div>
			        </div>
			    </LayoutPanel>

                {/* 2 */}
                <LayoutPanel theme="mer-theme--light mer-bg--ui-light"
	        				 padding="mer-pt-ju pb-0"
                             id="why">
	                <div className="container">
	                    <div className="row mer-panel">
	                    	
	                        <div className="col-10 col-sm-8 col-lg-6 mer-text--size-lg text-center">
	                            <div className="content">
	                                <h1 className="mer-color--primary-cyan">Why choose to carbon offset with Kleenheat?</h1>
                                    <div>
                                        <LinkModal 
                                            modalContents={modalContents}
                                            useModal="true"
                                            displayContent={<small>Terms & conditions apply</small>}
                                            title="Terms & Conditions"
                                            modalTitle="Terms & Conditions"
                                            modalContentName="terms-carbonoffset-opt-in"
                                            okayButtonLabel="Close"
                                        />
                                    </div>
	                            </div>
	                        </div>
	                    </div>
	                </div>
	            </LayoutPanel>

		    	{/* usp */}
		    	<LayoutPanel background="mer-bg--ui-light"
			                 padding="mer-py-lg"
			                 id="offer">
			        <div className="container">
			        	<Panel data={panelData} 
			                   id="panel-1"
							   modalTitle="Terms & Conditions"
							   modalContents={modalContents}
							   modalContentName="terms-carbonoffset-opt-in"
							   modalDisplayContent={<span><sup>*</sup>Terms and conditions apply</span>}
						/>
                        <Panel data={panelData} 
			                   id="panel-2"
						/>
                        <Panel data={panelData} 
			                   id="panel-3"
                               imgLocal={coNeutralProduct}
						/>
						<Panel data={panelData} 
			                   id="panel-4"
                               imgLocal={wescefLogo}
						/>
			        </div>
		        </LayoutPanel>

                {/* 3 */}
                <LayoutPanel background="mer-bg--gs-grey-lighter"
			                 padding="mer-py-lg"
			                 id="pricing">
			        <div className="container">
			            <div className="row mer-panel">

			              	<div className="col-10 col-sm-7 col-lg-6 order-2 order-sm-1">
			              		<div className="content">
				                	<h2 className="mer-typography--headline3">How is my contribution calculated?</h2>
                                    <p><strong>Carbon offset contributions are calculated based on your household’s consumption of natural gas.</strong></p>
                                    <p>To calculate your carbon offset contribution, your units of natural gas consumption are multiplied by a unit price. The unit price is set by Kleenheat and based on the cost of acquiring offset units. The unit price set by Kleenheat may fluctuate over time, due to the availability of carbon offsets for purchase.</p>
									<p>Once you opt-in, your carbon offset contribution will be applied to the whole billing period.</p>
                                    <div>
                                        <LinkModal 
                                            modalContents={modalContents}
                                            useModal="true"
                                            displayContent="View prices and fees for carbon neutral natural gas customers."
                                            title="Prices and fees for all natural gas customers"
                                            modalTitle="Prices and fees for all natural gas customers"
                                            modalContentName="standard_prices_and_fees"
                                            okayButtonLabel="Close"
                                        />
                                    </div>
	          					</div>
			              	</div>

							<div className="mer-panel__images col-6 col-sm-3 offset-sm-1 order-1 order-sm-2">
                                <Image className="mer-panel__image"
                                        src={srcFlowmojiInvoice}
                                        alt="Invoice flowmoji"
                                />
						    </div>
			            </div>
			        </div>
			    </LayoutPanel>

                <Faq panelBackground="mer-bg--ui-light"
		  			 panelPadding="mer-py-lg"
		  			 data={modifiedAccordionList}
					 id="faq"
                     border="mer-border--top mer-border--bottom" />
		    </LayoutMain>
			<Footer />
		</>
	)
}

export default CarbonOffsetPage;